import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest } from '../../../../../utils/request';
import { isBrowser, formatDateTime } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';


function formatRow(item) {
  const {id, slug, price, min_age: minAge, created_at: created, status, name } = item;
  return {
    name: <><span className="font-bold">{name}</span><br />Slug: {slug}</>,
    price: <NumberFormat value={price} displayType="text" thousandSeparator prefix="$" />,
    min_age: minAge,
    status: <>{status ? 'Active' : 'Inactive'}<br />Created: {formatDateTime(created)}</>,
    id,
  };
}

const AdminLeagues = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/leagues',
        label: 'Leagues',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadLeagues = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/leagues${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setLeagues(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadLeagues();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'price',
      label: 'Price',
      isBold: true,
    },
    {
      key: 'min_age',
      label: 'Min Age',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/app/admin/league/edit?id=${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit
        </Link>
      ),
    },
  ];

  const actionLinks = [
    {
      label: 'Create League',
      href: '/app/admin/league',
    },
  ];

  const baseUrl = '/app/admin/leagues';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/leagues',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 1,
            label: 'Active',
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    ],
    submitLabel: 'Search Leagues',
  };

  return (
    <AdminContainer>
      <SEO title="Leagues" />
      <Heading title="Leagues" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Leagues" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminLeagues;
